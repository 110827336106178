<template>
    <div class="medicalRecordsDetails">
        <div class="detail-head" :style="!foldType?'':'background-size:100% auto'">
            <div class="head-container">
                <div class="container-patientinfo" ref="patientinfo" :class="{'container-fold':foldType}">
                    <p class="info-detail">
                        <span class="name">{{$t("patient.recordsPatientName",{name:medicalInfo.patientName})}}</span>
                        <!-- iswoman -->
                        <span class="gender" :class="{'iswoman':medicalInfo.patientGender == 2}"></span>
                        <span class="agetag" v-if="medicalInfo.patientAge">{{medicalInfo.patientAge}}{{$t("patient.annum")}}</span>
                    </p>
					<p class="detail-Idcard idcard">
                        <span class="lable">
                            {{$t("patient.IDCard")}}（{{medicalInfo.patientIdType?IdColumns.find(item => medicalInfo.patientIdType == item.value).text:''}}）：
                        </span>
                        <span class="val">
                            {{medicalInfo.patientIdCode}}
                        </span>
                    </p>
                    <!-- <p class="idcard belong-account">{{$t("patient.belongaccount",{name:"@乂氼"})}} <img src="@/assets/img/patient-gender-man.png" class="wxpic"></p> -->
                </div>
                <div class="container-hospinfo" ref="patienthosp" >
                    <p class="info-item" v-if="medicalInfo.type == 137002">
                        <span class="lable">{{$t("patient.hosptime")}}:</span>
                        <span class="value">
                            {{getLocalMinutesTime(medicalInfo.inHospitalDt)}}
                        </span>
                    </p>
                    <p class="info-item" v-if="medicalInfo.type == 137002"> 
                        <span class="lable">{{$t("patient.leavehosp")}}:</span>
                        <span class="value">
                            {{medicalInfo.outHospitalDt?getLocalMinutesTime(medicalInfo.outHospitalDt) : $t("patient.inhosp")}}
                        </span>
                    </p>
                    <p class="info-item" v-if="medicalInfo.type == 137002"> 
                        <span class="lable">{{$t("patient.ward")}}:</span>
                        <span class="value">
                            {{medicalInfo.zoneName}}
                        </span>
                    </p>
                    <p class="info-item" v-if="medicalInfo.type != 137002"> 
                        <span class="lable">{{$t("patient.clinicTime",{time:''})}}</span>
                        <span class="value">
                            {{getLocalMinutesTime(medicalInfo.clinicDt)}}
                        </span>
                    </p>
                    
                    <div class="info-two" v-if="medicalInfo.type != 137002">
                        <p class="info-item" > 
                            <span class="lable">{{$t("patient.clicpDictor")}}:</span>
                            <span class="value">
                                {{medicalInfo.doctorName}}
                            </span>
                        </p>
                        <p class="info-item"> 
                            <span class="lable">{{$t("patient.assistantdoctor")}}:</span>
                            <span class="value">
                                {{medicalInfo.nurseName}}
                            </span>
                        </p>
                    </div>
                    <p class="info-item" v-if="medicalInfo.type != 137002"> 
                        <span class="lable">{{$t("patient.clinicNumber")}}:</span>
                        <span class="value">
                            {{medicalInfo.clinicNumber}}
                        </span>
                    </p>
                    
                    <div class="info-two" v-if="medicalInfo.type == 137002">
                        <p class="info-item" > 
                            <span class="lable">{{$t("patient.hospital")}}:</span>
                            <span class="value">
                                {{medicalInfo.hospitalNumber}}
                            </span>
                        </p>
                        <p class="info-item"> 
                            <span class="lable">{{$t("patient.bednum")}}:</span>
                            <span class="value">
                                {{medicalInfo.bedNumber}}
                            </span>
                        </p>
                    </div>
                    <div class="info-two" v-if="medicalInfo.type == 137002">
                        <p class="info-item"> 
                            <span class="lable">{{$t("patient.directordoctor")}}:</span>
                            <span class="value">
                                {{medicalInfo.doctorName}}
                            </span>
                        </p>
                        <p class="info-item"> 
                            <span class="lable">{{$t("patient.dutynurse")}}:</span>
                            <span class="value">
                                {{medicalInfo.nurseName}}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <i class="unfold" @click="foldType = !foldType,foldFun()" :class="{'fold':foldType}"></i>
        </div>
        <div class="diseasehistory-container">
            <div class="container-btn">
                <span class="btn" @click="goAdd">{{$t("patient.customrecords")}}</span>
                <p class="desc">{{$t("patient.casehistoryNum",{num:caseHistoryList.length})}}</p>
            </div>
        </div>
        <div class="caseHistory-container">
            <div class="caseHistory-list">
                <div class="caseHistory-item" v-for="(item,index) in caseHistoryList" :key="index" @click="seeDetails(item)" >
                    <div class="item-title">
                        <p class="title" >
                            <span class="icon"></span>
                            <span class="txt">{{caseHistoryHead[item.type]}}</span>
                        </p>
                        <van-popover v-model="item.popoverShow" trigger="click">
                            <div class="popover-btn">
                                <span @click="editCase(item)">{{$t("patient.editbtn")}}</span>
                                <span @click="deletecase(item,index)">{{$t("patient.deletebtn")}}</span>

                            </div>
                            <template #reference>
                                <i class="more-icon" @click="operation($event,item)" v-if="item.cId == $store.getters.id"></i>
                            </template>
                        </van-popover>
                    </div>
                    <p class="item-info" >
                        <span class="lable">{{$t("patient.recordtime")}}：</span>
                        <span class="value">
                            {{getLocalMinutesTime(item.logTime)}}
                        </span>
                    </p>
                    <p class="item-info" >
                        <span class="lable">{{item.logTitle}}：</span>
                        <span class="value">{{item.log}}</span>
                    </p>
                </div>
                <!-- <div class="caseHistory-item">
                    <div class="item-title">
                        <p class="title">
                            <span class="icon"></span>
                            <span class="txt">病程记录</span>
                        </p>
                        <i class="more-icon"></i>
                    </div>
                    <p class="item-info">
                        <span class="lable">{{$t("patient.recordtime")}}：</span>
                        <span class="value">2022-04-05 15:39</span>
                    </p>
                    <p class="item-info">
                        <span class="lable">{{$t("patient.recordcontent")}}：</span>
                        <span class="value">今天有所好转，过段时间就可...</span>
                    </p>
                </div> -->
            </div>
            <div class="caseHistory-empty" v-if="caseHistoryList.length==0">
                <img src="@/assets/img/case-history-img.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { medicalDetail, traceQuery, traceDel } from "@/api/patient";
import { getLocalMinutesTime } from "@/utils/date";
import { IdColumns } from "@/utils/staticData"

export default {
    data(){
        return{
            foldType:false,
            medicalid:this.$route.query.medicalId,
            patienthospHeight:0,
            IdColumns,
            patientinfoHeight:0,
            caseHistoryList:[],
            medicalInfo:{},
            getLocalMinutesTime,
            caseHistoryHead:{
                "138001":this.$t("patient.caseHistoryHead1"),
                "138002":this.$t("patient.caseHistoryHead2"),
                "138003":this.$t("patient.caseHistoryHead3"),
                "138004":this.$t("patient.caseHistoryHead4"),
                "138005":this.$t("patient.caseHistoryHead5"),
                "138006":this.$t("patient.caseHistoryHead6"),
            }
        }
    },
    created(){
        document.title = this.$t("patient.visidetail")
        this.getMedicalData();
        this.getcaseHistoryData();
    }, 
    mounted(){
        
    },
    methods:{
        async getMedicalData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await medicalDetail({id:this.medicalid});
            toast.close()
            if(result.data.errorCode == 0){
                this.medicalInfo = result.data.data;
                this.$nextTick(()=>{
                    this.foldFun();
                })
            }
        },
        operation(e,item){
            item.popoverShow = true;
            this.$forceUpdate();
            e = e || window.event;
            if (e.stopPropagation) { //W3C阻止冒泡方法
                e.stopPropagation();
            } else {
                e.cancelBubble = true; //IE阻止冒泡方法
            }
        },
        // 编辑 
        editCase(item){
            this.$router.push({
                path:"/patient/addcustomcaseHistory",
                query:{
                    medicalId:this.medicalid,
                    caseId:item.id
                }
            })
        },
        // 删除病历
        async deletecase(item,index){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await  traceDel({
                id:item.id
            })
            toast.close()
            if(result.data.errorCode == 0){
                // this.$router.go(-1)
                this.$Toast(this.$t("comonTxt.operationsuccess"))
                this.caseHistoryList.splice(index,1)
            }
        },
        // 获取病历数据
        async getcaseHistoryData(){
            let result = await traceQuery({
                medicalRecordId:this.medicalid
            })
            if(result.data.errorCode == 0){
                this.caseHistoryList = result.data.data;
                this.caseHistoryList.map(item=>{
                    item.popoverShow = false
                })
            }
        },
        // 添加自定义病历
        goAdd(){
            this.$router.push({
                path:"/patient/addcustomcaseHistory",
                query:{
                    medicalId:this.medicalid
                }
            })
            
        },
        // 查看详情
        seeDetails(item){
            this.$router.push({
                path:"/patient/casehistorydetails",
                query:{
                    medicalId:this.medicalid,
                    caseId:item.id
                }
            })
        },
        foldFun(){
            if(this.foldType){
                this.$refs.patienthosp.style.height = '0px';
                this.$refs.patienthosp.style.padding = '0px';
                this.$refs.patientinfo.style.height = '1.95rem';
            }else{
                if(this.patienthospHeight == 0){
                    this.patienthospHeight = this.$refs.patienthosp.scrollHeight
                }
                if(this.patientinfoHeight == 0){
                    this.patientinfoHeight = this.$refs.patientinfo.scrollHeight
                }
                this.$refs.patienthosp.style.height = this.patienthospHeight + 'px'; 
                this.$refs.patienthosp.style.padding = '0.6rem';
                this.$refs.patientinfo.style.height = this.patientinfoHeight + 'px';
                this.$refs.patientinfo.style.padding = '0.6rem';

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.medicalRecordsDetails{
    min-height: 100%;
    background: #F5F6F9;
    overflow: auto;
    .detail-head{
        // height: 10rem;
        box-sizing: border-box;
        background: url("~@/assets/img/hospital-detail-head-back.png") no-repeat;
        background-size: 100% 100%;
        padding: 1.6rem 0.6rem 0.5rem 0.6rem;
        position: relative;
        transition: all .3s linear 0s;
        background-position-y:bottom;
        *{transition: all .3s linear 0s;}
        &::after{
            position: absolute;
            width:7.5rem;
            height: 1.6rem;
            content: ' ';
            background: url("~@/assets/img/clip-icon.png") no-repeat;
            background-size: 100% 100%;
            left: 50%;
            top: 0.5rem;
            transform: translate(-50%,0);
        }
        .head-container{
            background: url("~@/assets/img/hospital-detail-head-container-back.png") no-repeat;
            background-size: 100% 100%;
            padding: 0.8rem 0.4rem 0.4rem 0.4rem;
            border-radius: 0.32rem 0.32rem 0 0;
            .container-patientinfo{
                padding: 0.6rem;
                border-radius: 0.32rem;
                background: #fff;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    width: 87%;
                    border-bottom: 0.02rem dashed #C7C8C9;
                    content: ' ';
                    bottom: 0;
                    left: 6.5%;
                }
                .info-detail{
                    display: flex;
                    align-items: center;
                    .name{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        max-width: 8rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .gender{
                        margin: 0 0.32rem;
                        width: 0.7rem;
                        display: inline-block;
                        height: 0.7rem;
                        background: url("~@/assets/img/patient-gender-man.png");
                        background-size: 100% 100%;
                    }
                    .iswoman{
                        background: url("~@/assets/img/patient-gender-woman.png");
                        background-size: 100% 100%;
                    }
                    .agetag{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        padding: 0.06rem 0.3rem;
                        border-radius: 0.4rem;
                        border: 0.02rem solid #666666;
                    }
                }
                .idcard{
                    margin: 0.4rem 0 0 0;
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    .wxpic{
                        width: 0.76rem;
                        height: 0.76rem;
                    }
                    display: flex;
					align-items: flex-start;
					.lable{
						flex: 0 0 auto
					}
					.val{
						flex: 1;
						word-break:break-all
					}
                }
                .belong-account{
                    margin: 0;
                }
            }
            .container-hospinfo{
                padding: 0.6rem 0.6rem 0.6rem 0.6rem;
                background: #fff;
                border-radius: 0.32rem;
                overflow: hidden;
                box-sizing: border-box;
                .info-item{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 0.4rem;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .lable{
                        font-weight: 600;
                        font-family: Regular;
                    }
                }
                .info-two{
                    display: flex;
                    .info-item{
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &:last-child{
                            margin-bottom: 0.4rem;
                        }
                    }
                }
            }
        }
        .container-fold{
            // height: 2rem;
            overflow: hidden;
            box-sizing: border-box;
        }
        .unfold{
            width: 2.08rem;
            height: 0.64rem;
            position: absolute;
            background: url("~@/assets/img/fold-top.png") no-repeat;
            background-size: 100% 100%;
            left: 50%;
            transform: translate(-50%,0);
            bottom: 0;
        }
        .fold{
            background: url("~@/assets/img/fold-bottom.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .container-btn{
        margin: 0.8rem 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .btn{
            width: 5.4rem;
            height: 1.4rem;
            line-height: 1.4rem;
            background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
            border-radius: 0.24rem;
            font-size: 0.64rem;
            text-align: center;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        .desc{
            margin-top: 0.4rem;
            font-size: 0.56rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
    .caseHistory-container{
        .caseHistory-list{
            padding: 0 0.6rem;
            .caseHistory-item{
                padding: 0.5rem 0.7rem;
                background: #FFFFFF;
                margin-bottom: 0.5rem;
                box-shadow: 0 0.08rem 0.24rem 0 rgba(203, 219, 235, 0.55);
                border-radius: 0.32rem;
                .item-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title{
                        display: flex;
                        align-items: center;
                        .icon{
                            width: 0.72rem;
                            height: 0.72rem;
                            margin-right: 0.3rem;
                            background: url("~@/assets/img/casehistory-title-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .txt{
                            font-size: 0.64rem;
                            font-family: PingFang SC;
                            font-weight: 600;
                            color: #333333;
                        }
                    }
                    .more-icon{
                        width: 0.2rem;
                        height: 0.52rem;
                        display: block;
                        background: url("~@/assets/img/casehistory-title-moreicon.png") no-repeat ;
                        background-size: 0.12rem 0.52rem;
                        background-position: center;
                        padding: 0.2rem;
                    }
                }
                .item-info{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    margin-top: 0.34rem;
                    font-weight: 400;
                    display: flex;
                    color: #666666;
                    .lable{
                        flex: 0 0 auto;
                        font-weight: 600;
                    }
                    .value{
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .caseHistory-empty{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 8.4rem;
                height:5.6rem;
                margin: 2rem 0;
            }
        }
    }
}
.popover-btn{
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    span{
        font-size: 0.6rem;
        padding: 0 0.3rem;
        &:last-child{
            margin-top: 0.2rem;
        }
    }
}
</style>